import { Alert, AlertDescription, chakra } from '@chakra-ui/react';
import React from 'react';

const DataFetchAlert = ({ className }: { className?: string }) => {
  return (
    <Alert status="warning" width="fit-content" className={ className }>
      <AlertDescription>
        出了点问题。请尝试刷新页面或稍后再返回。
      </AlertDescription>
    </Alert>
  );
};

export default chakra(DataFetchAlert);
